

import React, { version } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { APIUserCreate,APIUserRoleUpdate, APIVersionList, APIPercentageList, APIPercentageUpdate, APIVersionUpdate} from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { headers } from '../../Utilities/Env';

export default class AppSettings extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        

        showAlert:false,
        VersionNumber:"",
        VersionId:"",
        PercentageName:"",
        PercentageId:"",



        SuccessPostMsg:'',
        ErrorPostMsg:'',

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowEditImageModel:false,
        ShowStatusModel:false,

        
        StatusId:'',
        AllInputsRequiredError:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

        //
        NewPercentage:'',
        NewVersion:'',


        
    }    
}

componentDidMount(){
    this.loadData();
}

loadData  = () =>{
    axios.get(APIVersionList,{headers})
    .then(res => {
        let number = res.data.version; 
        let id = res.data.id; 
        this.setState({VersionId:id})
        this.setState({VersionNumber:number})
        })
    .catch(error=>{console.log(error);})
    axios.get(APIPercentageList,{headers})
    .then(res => {
        let number = res.data.percentage; 
        let id = res.data.id; 
        this.setState({PercentageId:id})
        this.setState({PercentageNumber:number})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}


setPercentage = (text) =>{this.setState({NewPercentage:text.target.value})}
setVersion = (text) =>{this.setState({NewVersion:text.target.value})}

postPercentage =  async () => 
    {
        let percentage = this.state.NewPercentage;
        let updateId = this.state.PercentageId;


        if (percentage.length === 0)
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('percentage', percentage);
            formData.append('updateId', updateId);
        
            try {
                    const response = await axios.put(APIPercentageUpdate, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.loadData ();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
}

postVersion =  async () => {
        let version = this.state.NewVersion;
        let updateId = this.state.VersionId;

        if (version.length== 0)
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('version', version);
            formData.append('updateId', updateId);
        
            try {
                    const response = await axios.put(APIVersionUpdate, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.loadData();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
        }
}


render() {
    const {show,ShowDetailsModel,ShowStatusModel,showAlert} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>
                {this.state.ShowAxiosErrorAlert ?(<>
                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                        <p>{this.state.AllInputsRequiredError}</p>
                        <p>{this.state.AxiosErrorMessage}</p>
                        <p>{this.state.AxiosErrorResponse}</p>
                    </div>
                </>):(<></>)}

                <br></br>
                {this.state.ShowAlertSuccessPostMsg ?(<>
                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                        {this.state.SuccessPostMsg}
                    </div>
                </>):(<></>)}
                {this.state.ShowAlertErrorPostMsg ?(<>
                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                        {this.state.ErrorPostMsg}
                    </div>
                </>):(<></>)}
                {this.state.ShowAlertAllInputsRequired ?(<>
                    <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                        {this.state.AllInputsRequiredError}
                    </div>
                </>):(<></>)}
                <section class="section">
                    <div class="row">
                        <div class="col-lg-6">

                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">App Version</h5>
                            <table className="table table-hover"  id="table">
                                <thead>
                                    <tr>
                                        <th className="tr-td-labels text-center"> No</th>
                                        <th className="tr-td-labels text-center">Version Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tr-td-labels text-center">1</td>
                                        <td className="tr-td-labels text-center">{this.state.VersionNumber}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='my-grid-container-2-columns' >
                                <div className='my-grid-item'>
                                    <p className="primary-text" >Change Percentage</p>
                                    <input type="text" onChange={text=>this.setVersion (text)} className="form-control form-inputs " autoComplete="off" placeholder="Numbr"/>
                                </div>
                                <div className='my-grid-item'>
                                    <p className="primary-text" >Save Changes</p>
                                    <Button variant="success" onClick={()=>this.postVersion ()}>
                                        Update Version
                                    </Button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">App Percentage</h5>
                            <table className="table table-hover"  id="table">
                                <thead>
                                    <tr>
                                        <th className="tr-td-labels text-center"> No</th>
                                        <th className="tr-td-labels text-center">Percentage Number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="tr-td-labels text-center">1</td>
                                        <td className="tr-td-labels text-center">{this.state.PercentageNumber} %</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='my-grid-container-2-columns' >
                                <div className='my-grid-item'>
                                    <p className="primary-text" >Change Percentage</p>
                                    <input type="text" onChange={text=>this.setPercentage (text)} className="form-control form-inputs " autoComplete="off" placeholder="Numbr"/>
                                </div>
                                <div className='my-grid-item'>
                                    <p className="primary-text" >Save Changes</p>
                                    <Button variant="success" onClick={()=>this.postPercentage()}>
                                        Update Percentage
                                    </Button>
                                </div>
                            </div>
                            </div>
                        </div>

                        </div>
                    </div>
                </section>
                </div>
                    {/* End col */}
            </div>
);
}}
