

import React from 'react';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { APIOrderChangeStatus, APIOrdersList, APIProductFileName, APISOrderDelete, APISupplierList} from '../../Utilities/APIS';
import { ERROR_POST } from '../../Utilities/Errors';
import { calculateItemTotalCash, calculatePercentageCommission, calculatePercentageCommissionCash, calculateSupplierCash, formatNumberWithComma, getOrderStatus, getSupplierContactById, getSupplierNameById } from '../../Utilities/Functions';
import { headers } from '../../Utilities/Env';
export default class Orders extends React.Component {
constructor(props){
    
    super(props);
    this.state = {
        OrdersData:[],
        OrderListData:[],
        SuppliersListData:[],

        ItemShowCustomer:'',
        ItemShowLocation:'',
        ItemShowPrice:'',
        ItemShowOrderDate:'',
        ItemShowOrderNumber:'',
        

        // models 
        show:false,
        ShowEditTextModel:false,
        ShowDeleteModel:false,

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',

        // status
        OrderStatus:'',
        StatusId:'',
        deleteId:'',
    }    
}

componentDidMount(){
    this.loadOrders();
}

loadOrders  = () =>{

    axios.get(APIOrdersList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults1 =JSON.parse(results); 
        let jsonResults =jsonResults1.reverse(); 
        this.setState({ProductsItems:jsonResults})
        this.setState({OrdersData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
    axios.get(APISupplierList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SuppliersListData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
setOrderStatus = (text) =>{this.setState({OrderStatus:text.target.value})}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false});}
handleShowDetailsModel = (id,customer,price,location,orderDate,orderNumber,orderList) => {
    this.resetModelShowAlerts();
    this.setState({StatusId:id})
    this.setState({deleteId:id})
    this.setState({ItemShowCustomer:customer});
    this.setState({ItemShowPrice:price});
    this.setState({ItemShowLocation:location});
    this.setState({ItemShowOrderDate:orderDate});
    this.setState({ItemShowOrderNumber:orderNumber});
    this.setState({OrderListData: JSON.parse(orderList)});
    this.setState({ShowDetailsModel:true});
}
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (name,id) => {
    this.setState({ItemShowName:name});
    this.setState({deleteId:id});
    this.setState({ShowDeleteModel:true});
}
resetModelShowAlerts = () =>{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
}
changOrderStatus =  async () => {
    let status = this.state.OrderStatus;
    let updateId = this.state.StatusId;

    if (status.length === 0)
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:"Status Not Selected"});}
    else
    {
        const formData = new FormData();
        formData.append('status', status);
        formData.append('updateId', updateId);
    
        try {
                const response = await axios.put(APIOrderChangeStatus , formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.loadOrders();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}

deleteOrder =  async () => {
    let deleteId = this.state.deleteId
    try {
            const response = await axios.delete(APISOrderDelete+deleteId, {headers});
            this.setState({ShowAlertSuccessPostMsg:true});
            this.setState({ShowAlertErrorPostMsg:false});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({SuccessPostMsg:response.data});
            this.loadOrders();
        } 
        catch (error) {
            this.setState({ShowAlertErrorPostMsg:true});
            this.setState({ShowAlertAllInputsRequired:false});
            this.setState({ShowAlertSuccessPostMsg:false});
            this.setState({ErrorPostMsg:ERROR_POST+error.message})
            }
}
render() {
    const {showAlert,ShowDeleteModel,OrdersData,ShowDetailsModel} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Clients Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                {/* <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New User
                                    </Button>
                                </ButtonGroup> */}
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                <p>{this.state.AllInputsRequiredError}</p>
                                <p>{this.state.AxiosErrorMessage}</p>
                                <p>{this.state.AxiosErrorResponse}</p>
                            </div>
                        </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Supplier</th>
                                            <th className="tr-td-labels text-center">Date</th>
                                            <th className="tr-td-labels text-center">Client</th>
                                            <th className="tr-td-labels text-center">Order Number</th>
                                            <th className="tr-td-labels text-center">Price</th>
                                            <th className="tr-td-labels text-center">Status</th>
                                            <th className="tr-td-labels text-center">Details</th>
                                            {/* <th className="tr-td-labels text-center">Remove</th> */}
                                        </tr>
                                    </thead>


                                    <tbody>
                                        {OrdersData && OrdersData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{getSupplierNameById (item.supplier,this.state.SuppliersListData)}</td>
                                            <td className="tr-td-labels text-center">{item.orderDate}</td>
                                            <td className="tr-td-labels text-center">{item.customer}</td>
                                            <td className="tr-td-labels text-center">{item.orderNumber}</td>
                                            <td className="tr-td-labels text-center">{ formatNumberWithComma(item.price)}</td>
                                            <td className="tr-td-labels text-center">{ getOrderStatus(item.status) }</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.id,item.customer,item.price,item.location,
                                                    item.orderDate,item.orderNumber,item.orderList
                                                )}>
                                                    Show Order
                                                </Button>
                                            </td>
                                            {/* <td className="tr-td-labels text-center">
                                                <Button variant="danger" onClick={()=>this.handleShowDeleteModel(item.orderNumber,item.id)}>
                                                    Delete Order
                                                </Button>
                                            </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Details Model   */}
                        <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                            <Modal.Header>
                            <Modal.Title>Order Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='my-grid-container-4-columns' >
                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                            <b>Customer</b><br></br>
                                            {this.state.ItemShowCustomer}
                                            </p>
                                        </div>
                                    </div>

                                    <div className='my-grid-item'>
                                        <div>
                                            <p className="primary-text" >
                                            <b>Location</b><br></br>
                                            {this.state.ItemShowLocation}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                        <b>Price</b><br></br>
                                        {this.state.ItemShowPrice}
                                        </p>
                                        </div>
                                    </div>

                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                            <b>Order Date</b><br></br>
                                            {this.state.ItemShowOrderDate}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='my-grid-item'>
                                        <div>
                                        <p className="primary-text" >
                                        <b>Order Number</b><br></br>
                                        {this.state.ItemShowOrderNumber}
                                        </p>
                                        </div>
                                    </div>
                                </div>

                                {this.state.OrderListData && this.state.OrderListData.map((item,index)=>(
                                    <div key={index}>
                                        <div class="alert border-primary alert-dismissible fade show" role="alert">
                                            <center>
                                                <img src={APIProductFileName+item.fileName} alt="Uploaded" className=" selected-upload-image" /><br></br>
                                                <span class="badge rounded-pill bg-danger">Percentage Is : {item.percentage} %</span>
                                            </center>
                                            <div className='my-grid-container-5-columns' >
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Supplier</b><br></br>
                                                        {getSupplierNameById (item.supplier,this.state.SuppliersListData)}
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Commission</b><br></br>
                                                        {formatNumberWithComma(calculatePercentageCommission(item.discount,item.percentage))}
                                                    </p>
                                                </div>

                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>With Out %</b><br></br>
                                                        {formatNumberWithComma(item.discount)}
                                                        </p>
                                                </div>
                                                
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Sold At %</b><br></br>
                                                        {formatNumberWithComma(item.discountPercentage)}
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Quantity</b><br></br>
                                                        {item.qty} Pcs
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Contact</b><br></br>
                                                        {getSupplierContactById (item.supplier,this.state.SuppliersListData)}
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Commi Cash</b><br></br>
                                                        {formatNumberWithComma(calculatePercentageCommissionCash(item.discount,item.percentage,item.qty))}
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Supp Cash</b><br></br>
                                                        {formatNumberWithComma(calculateSupplierCash (item.discount,item.qty))}
                                                        </p>
                                                </div>
                                                <div className='my-grid-item'>
                                                    <p className="primary-text" >
                                                        <b>Total in %</b><br></br>
                                                        {formatNumberWithComma(calculateItemTotalCash (item.discountPercentage,item.qty))}
                                                        </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <br></br>
                                <center>
                                    <div>
                                        <p className="primary-text" >Change Status</p>
                                        <select type="text" onChange={text=>this.setOrderStatus (text)} className="form-control form-inputs order-status-form" autoComplete="off" placeholder="Full Name">
                                            <option> Select </option>
                                            <option value="Pending">Pending</option>
                                            <option value="Delivered">Delivered</option>
                                        </select>
                                        <br></br>
                                        <Button variant="primary" onClick={()=>this.changOrderStatus ()}>
                                            Yes Save Changes
                                        </Button>
                                    </div>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </center>
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-between">
                            <Button variant="danger"  onClick={()=>this.deleteOrder()}>
                                Delete This Order
                            </Button>
                            <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                Close
                            </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Delete Model   */}
                        <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                            <Modal.Header>
                                <Modal.Title>Delete Order</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    <p>Are You Sure You Want to Delete This Order ??</p>
                                </div>
                                <div className='my-grid-container-2-columns' >
                                    <div className='my-grid-item'>
                                        <div>
                                            <p className="primary-text" >
                                                <b>Name</b><br></br>
                                                {this.state.ItemShowName}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <br></br>
                                {this.state.ShowAlertSuccessPostMsg ?(<>
                                    <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.SuccessPostMsg}
                                    </div>
                                </>):(<></>)}
                                {this.state.ShowAlertErrorPostMsg ?(<>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.ErrorPostMsg}
                                    </div>
                                </>):(<></>)}
                                {this.state.ShowAlertAllInputsRequired ?(<>
                                    <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                        {this.state.AllInputsRequiredError}
                                    </div>
                                </>):(<></>)}
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={()=>this.deleteOrder()}>
                                Yes Delete Item
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    
                    </div>
                    {/* End col */}
            </div>
);
}}
