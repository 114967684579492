

import React,{Component} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
import Header from './Navigation/Header';
import { APICategoryTotal, APIClientTotal, APIOrderTotal, APIProductTotal, APISliderTotal, APISupplierTotal } from '../Utilities/APIS';
import { headers } from '../Utilities/Env';

class Dashboard extends Component {
constructor(props){
    
    super(props);
    this.state = {
        OrdersTotal:'',
        SupplierTotal:'',
        ProductsTotal:'',
        SliderTotal:'',
        CategoryTotal:'',
        ClientsTotal:'',
    }    
}

componentDidMount(){
    this.loadData();
}

loadData = () =>{
    axios.get(APIOrderTotal,{headers})
    .then(res => {
        let results =res.data;
        this.setState({OrdersTotal:results})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
    axios.get(APIProductTotal,{headers})
    .then(res => {
        let results =res.data;
        this.setState({ProductsTotal:results})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
    
    axios.get(APICategoryTotal,{headers})
    .then(res => {
        let results =res.data;
        this.setState({CategoryTotal:results})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })

    axios.get(APISliderTotal,{headers})
    .then(res => {
        let results =res.data;
        this.setState({SliderTotal:results})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })

    axios.get(APIClientTotal,{headers})
    .then(res => {
        let results =res.data;
        this.setState({ClientsTotal:results})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
    axios.get(APISupplierTotal,{headers})
    .then(res => {
        let results =res.data;
        this.setState({SupplierTotal:results})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })




}


goToResearchers = () =>{this.props.navigate("/researchers");}     
render() {
    const { OrdersTotal,ProductsTotal,SliderTotal,CategoryTotal,ClientsTotal,SupplierTotal} =this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                    <strong>Dashboard</strong> 
                </div>
                {this.state.ShowAxiosErrorAlert ?(<>
                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                        <p>{this.state.AllInputsRequiredError}</p>
                        <p>{this.state.AxiosErrorMessage}</p>
                        <p>{this.state.AxiosErrorResponse}</p>
                    </div>
                </>):(<></>)}
                <div className="row">
                    <div className="col-xl-2 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                            <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Orders</div>
                                            <div className="h5 mb-0 font-weight-bold text-warning text-gray-800">{OrdersTotal}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-warning"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Products</div>
                                            <div className="h6 mb-0 font-weight-bold text-success text-gray-800">{ProductsTotal}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-success"></i>
                                        </div> 
                                    </div>
                                </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6 mb-4 ">
                            <div className="card border-left-primary shadow h-100 py-2">
                            <span   >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Categories</div>
                                            <div className="h6 mb-0 font-weight-bold text-primary">{CategoryTotal}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-primary"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                            <span >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Slider</div>
                                            <div className="h6 mb-0 mr-3 font-weight-bold  text-info  text-gray-800">{SliderTotal}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-eye-fill text-info"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                            <span >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Clients</div>
                                            <div className="h6 mb-0 mr-3 font-weight-bold  text-info  text-gray-800">{ClientsTotal}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-eye-fill text-info"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-2 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                            <span >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Supplier</div>
                                            <div className="h6 mb-0 mr-3 font-weight-bold  text-info  text-gray-800">{SupplierTotal}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-eye-fill text-info"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>
                </div>
            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


export default withNavigation(Dashboard);