

import React,{Component} from 'react';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import User1Icon from "../../Utilities/imgs/user1.png"

import Header from './Header';
import { APIUserListByEmail, APIUserUpdate, APIUserUpdatePassword } from '../../Utilities/APIS';
import { headers } from '../../Utilities/Env';

class Profile extends Component {
    
constructor(props){
    
    super(props);
    this.state = {
        userLoggedIn:false,
        EditProfileSuccess:'',
        EditPasswordSuccess:'',
        EditProfileError:'',
        EditPasswordError:'',

        ShowEditProfileSuccessAlert:false,
        ShowEditProfileErrorAlert:false,
        ShowEditPasswordSuccessAlert:false,
        ShowEditPasswordErrorAlert:false,
        ShowEditPasswordAlert:false,
        SelectedImage:null,
        profileImage:null,

        //  Password
        CurrentPassword:'',
        Password:'',
        PasswordRetype:'',
        PasswordInputsRequired:'',
        PasswordDoNotMatch:'',
        DBPasswordDoNotMatch:'',
        UserTypedPassword:'',

        UniversitiesData:[],
        userId:'',

        userEmail:'',
        userName:'',
        userImage:'',
    }    
}

componentDidMount(){
    this.loadUser();
}


loadUser = ()=>{
    const user = localStorage.getItem('UserDetails');
    if (user) {
        const jsonData = JSON.parse(user)
        let userName = jsonData.name;
        let userEmail = jsonData.email;
        let userId =  jsonData.id;
        let profileImage = jsonData.profileImage;

        
        

        this.setState({userName:userName});
        this.setState({userEmail:userEmail});
        this.setState({userId:userId});
        this.setState({profileImage:profileImage});
    }
}

getUserPassword = async () =>{
    let email = this.state.userEmail;
    const postRequest = await axios.get(APIUserListByEmail+email,{headers})
    let results = postRequest.data;
    let jsonString = JSON.stringify(results)
    let jsonResults =JSON.parse(jsonString);
    let userPassword = jsonResults.password;
    this.setState({CurrentPassword:userPassword});
}
setUserEmail = (text) =>{this.setState({userEmail:text.target.value})}
setUserName = (text) =>{this.setState({userName:text.target.value})}
setUserImage = (text) =>{this.setState({userImage:text.target.value})}
setPassword = (text) =>{ this.setState({Password:text.target.value})}
setPasswordRetype = (text) =>{ this.setState({PasswordRetype:text.target.value})}
setTypedUserPassword = (text) =>{ this.setState({UserTypedPassword:text.target.value});this.getUserPassword()}



  // Function to handle the image upload and conversion to base64
handleImageUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) 
        {
            const reader = new FileReader();
            
            reader.onloadend = () => {
            this.setState({SelectedImage:reader.result}); // Set the base64 image data to state
            console.log(reader.result)
            };

            reader.readAsDataURL(file); // Convert image to base64 string
        }
};

validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

updateUser = async () =>{

    let userId =  this.state.userId;
    let email = this.state.userEmail;
    let fullName = this.state.userName;
    // let profileImage = this.state.SelectedImage;

    if (fullName.length === 0 || email.length === 0)
    {
        this.setState({AllInputsRequired:"Name, Contact OR Email  Inputs Are Required"}); 
    }
    
    else
    {
        if (this.validateEmail(email)) 
            {
                const formData = new FormData();
                formData.append('fullName', fullName);
                formData.append('email', email);
                formData.append('updateId', userId);
                try
                    {
                        const postRequest = await axios.put(APIUserUpdate,formData,{headers})
                        let result = postRequest.data;
                        let Details = {name:fullName,email:email,id:userId};
                        localStorage.setItem('UserDetails',JSON.stringify(Details));
                        this.setState({EditProfileSuccess:result});
                        this.setState({ShowEditProfileSuccessAlert:true});
                        this.loadUser();
                    }
                    catch (error)
                        {this.setState({EditProfileError:error.message}); this.setState({ShowEditProfileErrorAlert:true});};
            } 
        else {this.setState({emailError: 'Please Enter A Valid Email Address.'});}
    }
}


updateUserPassword = async () =>{
    let userId =  this.state.userId;
    let password = this.state.Password;
    let passwordRetype = this.state.PasswordRetype;

    if (password.length === 0 || passwordRetype.length ===0)
    {this.setState({PasswordInputsRequired:"Password  Inputs Are Required"});}
    else
    {
        if (password !== passwordRetype)
            {this.setState({PasswordDoNotMatch:"Passwords Do Not Match"}); }
        else 
        {
            if (this.state.UserTypedPassword !== this.state.CurrentPassword)
                {this.setState({DBPasswordDoNotMatch:"Exiting Passwords Do Not Match New Password"}); }
            else 
            {
                const formData = new FormData();
                formData.append('password', password);
                formData.append('updateId', userId);
                try
                    {
                        const postRequest = await axios.put(APIUserUpdatePassword,formData,{headers})
                        let result = postRequest.data;
                        this.setState({EditPasswordSuccess:result});
                        this.setState({ShowEditPasswordSuccessAlert:true});
                    }
                    catch (error)
                        {this.setState({EditPasswordError:error.message}); this.setState({ShowEditPasswordErrorAlert:true});};
            }
        }
    }
}

        
render() {
    const { 
            userEmail,Password,
            userName,PasswordRetype,
            
            PasswordInputsRequired,UserTypedPassword,
            PasswordDoNotMatch,SelectedImage,profileImage,
            DBPasswordDoNotMatch,
        } =this.state;

return (
            <div className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>
                <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                    <strong className='strong-text primary-text' >User Profile </strong><span className='strong-text primary-text'>View</span>
                    <div className="custom-alert-btn-actions">
                        {/* <ButtonGroup className="mb-2">
                            <Button variant="primary" onClick={()=>this.handleShow()}>
                                Add New
                            </Button>
                        </ButtonGroup> */}
                        </div>
                </div><br/>
                    <div className="row">
                        <section className="section profile">
                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card">
                                        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                        <img src={User1Icon} alt="Profile" className="rounded-circle" />
                                        <h2>{userName}</h2>
                                        {/* <h3>{userLevel}</h3>
                                        <div className="social-links mt-2">
                                            <Link to={userTwitter} className="twitter"><i className="bi bi-twitter"></i></Link>
                                            <Link to={userFacebook} className="facebook"><i className="bi bi-facebook"></i></Link>
                                            <Link to={userInstagram} className="instagram"><i className="bi bi-instagram"></i></Link>
                                            <Link to={userLinkedin} className="linkedin"><i className="bi bi-linkedin"></i></Link>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>

                                    <div className="col-xl-8">
                                    <div className="card">
                                    <Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="overview" title="Overview">
                                            <div className="profile-tab-content">
                                                <div className="tab-pane fade show active profile-overview user-profile-card">

                                                    <h5 className="card-title">Profile Details</h5>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label ">Full Name</div>
                                                        <div className="col-lg-9 col-md-8">{userName}</div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-4 label">Email</div>
                                                        <div className="col-lg-9 col-md-8">{userEmail}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="editProfile" title="Edit Profile">
                                            <div className="profile-tab-content user-profile-card">
                                                {/* <div className="row mb-3">
                                                    <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                                                    <div className="col-md-4 col-lg-6">
                                                    <div>
                                                        {SelectedImage && (
                                                            <div>
                                                            <h3>New Image:</h3>
                                                                <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                            </div>
                                                        )}
                                                        </div>
                                                        <div className="pt-2">
                                                            <input type="file" accept="image/*" onChange={e=>this.handleImageUpload(e)} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="row mb-3">
                                                    <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input type="text" onChange={text=>this.setUserName(text)}  className="form-control form-inputs" id="fullName" value={userName} />
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                                                    <div className="col-md-4 col-lg-6">
                                                        <input onChange={text=>this.setUserEmail (text)} type="email" className="form-control form-inputs" id="Email" value={userEmail}/>
                                                    </div>
                                                </div>

                                                <div className="text-center">
                                                    <button type="submit" onClick={()=>{this.updateUser()}} className="btn btn-primary">Save Changes</button>
                                                </div><br></br>
                                                <div className="row mb-3">
                                                    <div className="col-md-7 col-lg-9">
                                                        {this.state.ShowEditProfileSuccessAlert ?(<> 
                                                            <Alert variant="primary">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditProfileSuccess}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Will Reflect When You Log In Again !!</p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}

                                                        {this.state.ShowEditProfileErrorAlert ?(<> 
                                                            <Alert variant="danger">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditProfileError}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Not Recorded You Try Again </p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="changePassword" title="Change Password">
                                            <div className='profile-tab-content user-profile-card'>
                                                <div className="row mb-3">
                                                    <label htmlFor="currentPassword" className="col-md-4 col-lg-3 col-form-label">Current Password</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input type="password" className="form-control form-inputs change-password-form-inputs" placeholder="**************"
                                                        onChange={text => this.setTypedUserPassword(text)} value={UserTypedPassword}
                                                        />
                                                        <p className="text-danger" >{DBPasswordDoNotMatch}</p>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="newPassword" className="col-md-4 col-lg-3 col-form-label">New Password</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input name="newpassword" type="password" className="form-control form-inputs change-password-form-inputs" value={Password}
                                                        placeholder="**************"
                                                        onChange={text => this.setPassword(text)} />
                                                        <p className="text-danger" >{PasswordDoNotMatch}</p>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Re-enter New Password</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <input name="renewpassword" type="password" className="form-control form-inputs change-password-form-inputs" 
                                                        placeholder="**************"
                                                            value={PasswordRetype}
                                                            onChange={text => this.setPasswordRetype(text)}
                                                        />
                                                        <p className="text-danger" >{PasswordDoNotMatch}</p>
                                                        <p className="text-danger" >{PasswordInputsRequired}</p>

                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <label htmlFor="renewPassword" className="col-md-4 col-lg-3 col-form-label">Save Now</label>
                                                    <div className="col-md-8 col-lg-9">
                                                        <button type="submit" onClick={()=>this.updateUserPassword()} className="btn btn-primary">Change Password</button>
                                                    </div>
                                                </div><br></br>
                                                <div className="row mb-3">
                                                    <div className="col-md-7 col-lg-9">
                                                        {this.state.ShowEditPasswordSuccessAlert ?(<> 
                                                            <Alert variant="primary">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditPasswordSuccess}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Will Reflect When You Log In Again !!</p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}

                                                        {this.state.ShowEditPasswordErrorAlert?(<> 
                                                            <Alert variant="danger">
                                                                <Alert.Heading>Response</Alert.Heading>
                                                                <p>{this.state.EditPasswordError}</p>
                                                                <hr />
                                                                <p className="mb-0">Changes Not Recorded You Try Again </p>
                                                            </Alert><br></br>
                                                        </>):(<></>)}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </Tab> 
                                    </Tabs>
                                    </div>
                                    </div>
                            </div>
                        </section>
                    </div>
                    </div>
            </div>
);
}}

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}

// Wrap the class component with the functional component to get access to navigate
export default withNavigation(Profile);
