
export const clearLocalStorageData = () =>{
    localStorage.removeItem('UserDetails');
}
export const formatNumberWithComma = (numb) => {
    let str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
}

export const convertToUpperCase = (Name) =>{
    return Name.toUpperCase();
}


export const getCategoryName=(ID,Data)=>{
    let Name = ' ';
    // console.log("Contr"+JSON.stringify(Data))
    let Id = parseInt(ID) ;
    Data.map((item,index)=>{if (item.id === Id){Name = item.name;}})
    return Name.toUpperCase();
    // const categoryName = data.find(item => item.name === "Category Category")?.name;
}
export const getSupplierNameById = (id,Data) => {
    let Id = parseInt(id) ;
    const record = Data.find((item) => item.id === Id);
    return record ? record.fullName : "Not found";
};
export const getSupplierContactById = (id,Data) => {
    let Id = parseInt(id) ;
    const record = Data.find((item) => item.id === Id);
    return record ? record.contact : "Not found";
};
export const getOrderStatus = (status)=>{
    let StringStatus = "";
    if (status === "Pending"){
        StringStatus = <span class="badge rounded-pill bg-danger">{status}</span>
    }
    if (status === "Delivered"){
        StringStatus = <span class="badge rounded-pill bg-success">{status}</span>
    }
    return StringStatus;
}
export const getProductStatus = (status)=>{
    let StringStatus = "";
    if (status === "Not-Active"){
        StringStatus = <span class="badge rounded-pill bg-danger">{status}</span>
    }
    if (status === "Active"){
        StringStatus = <span class="badge rounded-pill bg-success">{status}</span>
    }
    return StringStatus;
}
export const getSupplierStatus = (status)=>{
    let StringStatus = "";
    if (status === "Pending"){
        StringStatus = <span class="badge rounded-pill bg-danger">{status}</span>
    }
    if (status === "Approved"){
        StringStatus = <span class="badge rounded-pill bg-primary">{status}</span>
    }
    return StringStatus;
}

export const calculatePercentageCommission = (value,percentageValue)=>{
    const numericValue = parseInt(value);
    if (!isNaN(numericValue)){
        let value = parseInt((percentageValue / 100)*numericValue)
        return value;
    }
    return 0;
}
export const calculatePercentageCommissionCash = (value,percentageValue,qty)=>{
    const numericValue = parseInt(value);
    if (!isNaN(numericValue)){
        let value1 = parseInt((percentageValue / 100)*numericValue)
        let price = value1*qty;
        return price;
    }
    return 0;
}
export const calculateSupplierCash = (value,qty)=>{
    const numericValue = parseInt(value);
    if (!isNaN(numericValue)){
        let price = numericValue*qty;
        return price;
    }
    return 0;
}
export const calculateItemTotalCash = (value,qty)=>{
    const numericValue = parseInt(value);
    if (!isNaN(numericValue)){
        let price = numericValue*qty;
        return price;
    }
    return 0;
}