import { APP_URL } from "./Env";

const BaseUrl = APP_URL; // Set App url

// User 
export const APIUserCreate = BaseUrl+"/api/bronia/user/create";
export const APIUserListByEmail = BaseUrl+"/api/bronia/list/user/by/email/";
export const APIUsersList = BaseUrl+"/api/bronia/users/list/all";
export const APIUserUpdate = BaseUrl+"/api/bronia/user/update";
export const APIUserRoleUpdate = BaseUrl+"/api/bronia/user/role/update";
export const APIUserUpdatePassword = BaseUrl+"/api/bronia/user/password/update";
export const APIUserTotal = BaseUrl+"/api/bronia/user/total/records"



// Product
export const APIProductTotal = BaseUrl+"/api/bronia/products/total/records"
export const APIProductCreate = BaseUrl+"/api/bronia/products/create";
export const APIProductList = BaseUrl+"/api/bronia/products/admin/list/all";
export const APIProductFileName = BaseUrl+"/api/bronia/products/image/file/";
export const APIProductUpdateText = BaseUrl+"/api/bronia/products/item/text/update";
export const APIProductUpdateImage = BaseUrl+"/api/bronia/products/item/image/update";
export const APIProductDelete = BaseUrl+"/api/bronia/products/item/delete/";
export const APIProductStatus = BaseUrl+"/api/bronia/products/item/status/update";

// Category
export const APICategoryTotal = BaseUrl+"/api/bronia/categories/total/records"
export const APICategoryCreate = BaseUrl+"/api/bronia/categories/create";
export const APICategoryList = BaseUrl+"/api/bronia/categories/list/all";
export const APICategoryUpdateText = BaseUrl+"/api/bronia/categories/item/update";
export const APICategoryDelete = BaseUrl+"/api/bronia/categories/item/delete/";

// Slider 
export const APISliderTotal = BaseUrl+"/api/bronia/slider/total/records";
export const APISliderCreate = BaseUrl+"/api/bronia/slider/create";
export const APISliderList = BaseUrl+"/api/bronia/slider/list/all";
export const APISliderFileName = BaseUrl+"/api/bronia/slider/image/file/";
export const APISliderUpdateText = BaseUrl+"/api/bronia/slider/item/text/update";
export const APISliderUpdateImage = BaseUrl+"/api/bronia/slider/item/image/update";
export const APISliderDelete = BaseUrl+"/api/bronia/slider/item/delete/";

// Orders 
export const APIOrderTotal = BaseUrl+"/api/bronia/order/total/records"
export const APIOrdersList = BaseUrl+"/api/bronia/order/list/all";
export const APISOrderDelete = BaseUrl+"/api/bronia/order/item/delete/";
export const APIOrderChangeStatus = BaseUrl+"/api/bronia/order/item/status/update"


// Clients
export const APIClientsList = BaseUrl+"/api/bronia/clients/list/all";
export const APIClientTotal = BaseUrl+"/api/bronia/clients/total/records";

// Supplier
export const APISupplierList = BaseUrl+"/api/bronia/supplier/list/all";
export const APISupplierListByIdName = BaseUrl+"/api/bronia/supplier/id-and-fullName";
export const APISupplierTotal = BaseUrl+"/api/bronia/supplier/total/records";
export const APISupplierUpdateStatus = BaseUrl+"/api/bronia/supplier/status/update";

// Version
export const APIVersionList = BaseUrl+"/api/bronia/version/list/all";
export const APIVersionCreate = BaseUrl+"/api/bronia/version/create";
export const APIVersionUpdate = BaseUrl+"/api/bronia/version/update";

// Percentage
export const APIPercentageList = BaseUrl+"/api/bronia/percentage/list/all";
export const APIPercentageCreate = BaseUrl+"/api/bronia/percentage/create";
export const APIPercentageUpdate = BaseUrl+"/api/bronia/percentage/update";