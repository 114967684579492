import React,{useState,useEffect} from 'react';
import {Link,useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { clearLocalStorageData } from '../../Utilities/Functions';
import IsUserAuthenticated from './IsUserAuthenticated';
import User2Icon from "../../Utilities/imgs/user2.png"

function Header() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    // const [userProfileImage, setUserProfileImage] = useState(null);



    useEffect(() => {
        const user = localStorage.getItem('UserDetails');
        if (user) {
            const jsonData = JSON.parse(user)
            let userName = jsonData.name;
            // let profileImage = jsonData.profileImage;
            setUserName(userName);
            // setUserProfileImage(profileImage);

        }
    }, []);

    



    const logout = () => {
        clearLocalStorageData();
        navigate('/');
    };
    

    return (
        <>
            <IsUserAuthenticated/>
            {/* <Nav variant="pills" activeKey="1" onSelect={handleSelect}> */}
            <Nav variant="pills" activeKey="1" >
                <Nav.Item>
                    <Nav.Link eventKey="1"  as={Link} to="/dashboard">
                        Dashboard
                    </Nav.Link>
                </Nav.Item> &nbsp;&nbsp;
                

                <NavDropdown title="Main Menu" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">
                        <Link as={Link} to="/orders"> Orders</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                    <Link as={Link} to="/products">Products</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                    <Link as={Link} to="/categories">Categories</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                </NavDropdown>

                <NavDropdown title="Others" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">
                        <Link as={Link} to="/sliderImage">Slider</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                        <Link as={Link} to="/clients"> Clients</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />  
                    <NavDropdown.Item eventKey="4.1">
                        <Link as={Link} to="/supplier"> Suppliers</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey="4.1">
                        <Link as={Link} to="/appUsers"> Users</Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />     
                </NavDropdown>


                <NavDropdown title="Settings" id="nav-dropdown">
                    <NavDropdown.Item eventKey="4.1">
                        <Link as={Link} to="/settings">Settings</Link>
                    </NavDropdown.Item>   
                </NavDropdown>

            </Nav>
            <div className='user-profile-div'>
                <div className='my-grid-container-2-columns'>
                    <div className='my-grid-item-user'>
                        <img src={User2Icon} className="user-icon" alt='user-icon' />
                    </div>
                    <div className='my-grid-item-user'>
                    <NavDropdown title={userName} id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.2" as={Link} to="/profile" >Profile</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" onClick={logout} >Log Out </NavDropdown.Item>
                    </NavDropdown>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;