

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { APISupplierList, APISupplierUpdateStatus} from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { getSupplierStatus } from '../../Utilities/Functions';
import { headers } from '../../Utilities/Env';
export default class Supplier extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        

        showAlert:false,
        UsersData:[],

        UserFullName:'',
        UserRole:'',
        UserEmail:'',
        UserPassword:'',


        SuccessPostMsg:'',
        ErrorPostMsg:'',

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowEditImageModel:false,
        ShowStatusModel:false,

        ItemShowName:'',
        ItemShowContact:'',
        ItemShowLocation:'',
        SupplierStatus:'',
        StatusId:'',
        AllInputsRequiredError:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        
    }    
}

componentDidMount(){
    this.loadUsers();
}

loadUsers  = () =>{
    axios.get(APISupplierList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({UsersData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}


handleCloseStatusModel = () => {this.setState({ShowStatusModel:false});this.resetModelShowAlerts();}
handleShowStatusModel = (fullName,role,email,statusId) => {
    this.setState({ItemShowName:fullName});
    this.setState({ItemShowContact:role});
    this.setState({ItemShowLocation:email});
    this.setState({StatusId:statusId});
    this.setState({ShowStatusModel:true});
}

setUserFullName = (text) =>{this.setState({UserFullName:text.target.value})}
setUserEmail = (text) =>{this.setState({UserEmail:text.target.value})}
setUserPassword = (text) =>{this.setState({UserPassword:text.target.value})}
setSupplierStatus = (text) =>{this.setState({SupplierStatus:text.target.value})}



resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
    this.setState({ItemShowImage:null});

}

changSupplierStatus =  async () => {
    let status = this.state.SupplierStatus;
    let updateId = this.state.StatusId;

    if (status.length === 0)
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('status', status);
        formData.append('statusId', updateId);
    
        try {
                const response = await axios.put(APISupplierUpdateStatus, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                
                this.loadUsers();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}


render() {
    const {ShowDetailsModel,ShowStatusModel,showAlert,UsersData} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' > Suppliers Listings</strong><span className='strong-text primary-text'>View</span>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                <p>{this.state.AllInputsRequiredError}</p>
                                <p>{this.state.AxiosErrorMessage}</p>
                                <p>{this.state.AxiosErrorResponse}</p>
                            </div>
                        </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Name</th>
                                            <th className="tr-td-labels text-center">Contact</th>
                                            <th className="tr-td-labels text-center">Location</th>
                                            <th className="tr-td-labels text-center">Status</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {UsersData && UsersData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.fullName}</td>
                                            <td className="tr-td-labels text-center">{item.contact}</td>
                                            <td className="tr-td-labels text-center">{item.location}</td>
                                            <td className="tr-td-labels text-center">{getSupplierStatus(item.status)}</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowStatusModel(item.fullName,item.contact,item.location,item.id)}>
                                                    Change Status
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>

                            {/* Details Model   */}
                            <Modal show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adds On Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowContact}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowLocation}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                        
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Status Model   */}
                            <Modal show={ShowStatusModel} onHide={()=>this.handleCloseStatusModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Chang User Role</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Change User Role ??</p>
                                    </div>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Contact</b><br></br>
                                                    {this.state.ItemShowContact}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Location</b><br></br>
                                                    {this.state.ItemShowLocation}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <center>
                                        <div>
                                                <p className="primary-text" >Select Status</p>
                                                <select type="text" onChange={text=>this.setSupplierStatus (text)} className="form-control form-inputs" autoComplete="off" placeholder="Full Name">
                                                    <option> Select </option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Approved">Approved</option>
                                                </select>
                                                <div>
                                            </div>
                                            </div>
                                        </center>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseStatusModel ()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={()=>this.changSupplierStatus()}>
                                    Yes Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}
