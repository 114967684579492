
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LogIn from './Components/Auth/LogIn';
import Dashboard from './Components/Pages/Dashboard';

// Main Menu
import Products from './Components/Pages/Menu/Products';
import Categories from './Components/Pages/Menu/Categories';
import Orders from './Components/Pages/Menu/Orders';
import SliderImages from './Components/Pages/Menu/SliderImages';

// profile
import Profile from './Components/Pages/Navigation/Profile';

// Others
import AppUsers from './Components/Pages/Users/AppUsers';
import Clients from './Components/Pages/Users/Clients';
import Supplier from './Components/Pages/Users/Supplier';

// Settings
import AppSettings from './Components/Pages/Settings/AppSettings';

function App() {

	return (
        <Router >
			<Routes>
				<Route path="/" element={<LogIn/>} />

				<Route path="/dashboard" element={<Dashboard/>} />

				<Route path="/orders" element={<Orders/>} />
				<Route path="/products" element={<Products/>} />
				<Route path="/categories" element={<Categories/>} />

				<Route path="/sliderImage" element={<SliderImages/>} />
				<Route path="/profile" element={<Profile/>} />
				<Route path="/appUsers" element={<AppUsers/>} />
				<Route path="/clients" element={<Clients/>} />
				<Route path="/supplier" element={<Supplier />} />
				<Route path="/settings" element={<AppSettings/>} />

				{/* 
				<Route path="/supplierRules" element={<SupplierRules/>} />

				
				
				
				// 
				
				
				<Route path="/rolexEgg" element={<RolexEgg/>} /> */}
			</Routes>
        </Router>
		
);}

export default App;
